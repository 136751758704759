import React, {useContext, useState} from 'react'
import Default from 'gatsby-theme-carbon/src/templates/Default';
import {UserContext, isLoggedIn} from '@parallelpublicworks/entitysync';
import {PageDescription, InlineNotification} from 'gatsby-theme-carbon';
import { navigate, Link } from "gatsby"
import { siteMetadata } from "../../gatsby-config"
import {Button, Form, ToastNotification, TextInput} from 'carbon-components-react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import recaptchaSubmit from '../util/recaptchaSubmit'
import {useUserValidationState, validateUser} from '../util'

export default function RequestAccess({pageContext, location}) {
    const userContext = useContext(UserContext)
    const [requestSent, setRequestSent] = useState(false)
    const [error, setError] = useState(null)
    const { executeRecaptcha } = useGoogleReCaptcha();


    const validationState = useUserValidationState(true);
    const validationStateVals = {};
    const validationStateSetters = {}; 

    const [isLoading, setIsLoading] = useState(false);

    for(let key of Object.keys(validationState)){
      validationStateVals[key] = validationState[key][0];
      validationStateSetters[key] = validationState[key][1];
    }

    if(typeof userContext[0] !== `undefined` && isLoggedIn(userContext)){
        navigate('/provider-landing/submit-new-listing')
        return (
          <></>
        );
    }

	  pageContext = {...pageContext, frontmatter: {title: `Request Access`}};

    const SubmitUserButton = ({disabled}) => {
      return (
        <Button type="submit" disabled={disabled} className="request-access-submit-btn">
          Submit
        </Button>
      );
    }

    const onSubmit = async (e) => {
      e.preventDefault()
      e.persist()
      setIsLoading(true)
      setError(null)
      try { 
        const valid = await recaptchaSubmit(executeRecaptcha)
        if (!valid) throw new Error("Failed recaptcha")
        const inputs = e.target.querySelectorAll('input')
        let data = {}
        for (let i = 0; i < inputs.length; i++) {
          data[inputs[i].name] = { value: inputs[i].value } 
        }
        if(!validateUser(data, validationState, true)){
          setError('Please check the highlighted fields and submit the form again')
          setIsLoading(false)
        }else{
          const resp = await fetch(siteMetadata.drupalUrl+'/user/register?_format=json', {
            method: 'POST',
            mode: 'cors',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(data)
          })
          if (resp.status == 200) {
            setRequestSent(true)
          } else {
            const respJson = await resp.json()
            /**
             * need to check the respJson.message for the field values
             * set validation state on respective fields that appear
             */
            if (respJson.message) {
              let splitMessage = respJson.message.split(/\n/g)
              let message = splitMessage.shift();
              while (message.search('Unprocessable Entity') > -1) {
                message = splitMessage.shift();
              }
              setError(message)
            }
          }
          setIsLoading(false)
        }
      } catch (err) {
        setError(err.message)
      }
    };

    return (
		<Default pageContext={pageContext} location={location}>
            {requestSent && <InlineNotification className="inline-notification" kind="info">
              <p><strong>Thank you for your submission!</strong> </p>
              <p>We have received your request and are verifying your account now. You will receive an email within two business days on how to finish setting up your account.</p>
              <Link to="/">Return to homepage</Link>
            </InlineNotification>}
            {!requestSent && 
              <>
                <PageDescription>
                  <div>
                    <p>Please submit your information for approval</p>
                    <small className="request-access-desc">Providers and case management organizations certified by the Office of Developmental Disabilities Services can post available jobs on Impact Oregon for free. Please have either your provider enrollment I.D. or eXPRS I.D. to request access. If you are requesting access for a nursing agency, the provider enrollment I.D. or eXPRS I.D. is not required. Questions? Contact <a href="mailto:account-approval@impactoregon.careers">account-approval@impactoregon.careers</a></small>
                    <small className="request-access-desc">Hiring for Personal Support Workers is not available through this website. This should happen through the <a href="https://ohccworkforce.org/" rel="noopener noreferrer" target="_blank">Oregon Home Care Commission</a>, per the <a href="https://www.oregon.gov/DHS/SENIORS-DISABILITIES/HCC/PSW-HCW/Pages/Collective-Bargaining.aspx" rel="noopener noreferrer" target="_blank">Collective Bargaining Agreement</a>.</small>
                    <small className="request-access-desc"><Link to="/legal-disclaimers">Click here for our legal disclaimer.</Link></small>
                  </div>
                </PageDescription>
                {error && <div className="toast-container">
                <ToastNotification
                  caption=""
                  iconDescription="Close"
                  subtitle=""
                  timeout={0}
                  title={error}
                  kind="error"
                  lowContrast="true"
                  onCloseButtonClick={(e) => setError(null)}
                />
                </div>}
                <Form onSubmit={onSubmit}>
                <div className="submit-form submit-user-form">

                  <TextInput light
                             
                            labelText="Primary Contact"
                            invalid={validationStateVals.field_contact_name}
                            invalidText={validationStateVals.field_contact_name ? validationStateVals.field_contact_name : ""}
                            onChange={() => validationStateSetters.field_contact_name(false)}
                            type="text"
                            name="field_contact_name"/>
                  <TextInput light
                             
                            labelText="Organization Name"
                            invalid={validationStateVals.field_organization_name}
                            invalidText={validationStateVals.field_organization_name ? validationStateVals.field_organization_name : ""}
                            onChange={() => validationStateSetters.field_organization_name(false)}
                            type="text"
                            name="field_organization_name"/>
                  <TextInput light
                             
                            labelText="Email"
                            type="email"
                            invalid={validationStateVals.mail}
                            invalidText={validationStateVals.mail ? validationStateVals.mail : ""}
                            onChange={() => validationStateSetters.mail(false)}
                            name="mail"/>
                  <TextInput light
                             
                            labelText="Primary Phone"
                            invalid={validationStateVals.field_phone}
                            invalidText={validationStateVals.field_phone ? validationStateVals.field_phone : ""}
                            onChange={() => validationStateSetters.field_phone(false)}
                            type="phone"
                            placeholder="1 555-555-5555"
                            name="field_phone"/>
                  <TextInput light
                             
                            labelText="Username"
                            placeholder=""
                            invalid={validationStateVals.name}
                            invalidText={validationStateVals.name ? validationStateVals.name : ""}
                            onChange={() => validationStateSetters.name(false)}
                            name="name"/>
                  <TextInput light
                             
                            labelText="Website (optional)"
                            invalid={validationStateVals.field_website}
                            invalidText={validationStateVals.field_website ? validationStateVals.field_website : ""}
                            onChange={() => validationStateSetters.field_website(false)}
                            type="text"
                            placeholder="http://"
                            name="field_website"/>  
                  <TextInput light
                   
                            labelText="DBA (optional)"
                            type="text"
                            name="field_dba"/>  
                  <TextInput light
                             
                            labelText="Provider Enrollment ID or eXPRS ID"
                            placeholder="This is not required for nursing agencies."
                            invalid={validationStateVals.field_provider_or_express_id}
                            invalidText={validationStateVals.field_provider_or_express_id ? validationStateVals.field_provider_or_express_id : ""}
                            onChange={() => validationStateSetters.field_provider_or_express_id(false)}
                            type="text"
                            name="field_provider_or_express_id"/>    
                  </div>
                  <SubmitUserButton disabled={isLoading}  />
                </Form>
              </>
            }
		</Default>
    )
}
